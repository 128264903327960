import React from 'react'
import Main from '../Home/Home'

export default function Index() {
  return (
    <>
        <Main />
    </>
  )
}
