import React from 'react'
import './indexServices.css'
import HomeServices from '../HomeServices/HomeServices'

export default function IndexServices() {
  return (
    <div>
        <HomeServices />
    </div>
  )
}
