import React from 'react'
import Main from '../../Components/Main/Main'

export default function Home() {

  return (
    <>
      <Main/>
    </>
  )
}
