import React from 'react'
import MainServices from '../../Components/MainServices/MainServices'


export default function About() {
  return (
    <>
    <MainServices />
    </>
  )
}
